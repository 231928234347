// import './App.css';</>
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Navbar from './components/navbar/Navbar';
import Calltoaction from './components/calltoaction/Calltoaction';
import Footer from './components/footer/Footer';
import Blog from './components/blog/Blog';
import RegisterForm from './components/registerform/Registerform';
import LoginForm from './components/login_modal/LoginModal';

function Main() {
  return (
    <>
      <Calltoaction />
      <Footer />
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <div>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="blog" element={<Blog />} />
          <Route path="register" element={<RegisterForm />} />
          <Route path="login" element={<LoginForm />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
