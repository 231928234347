import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './NavbarStyles.css';
import { BiSearch } from 'react-icons/bi';
import { BsPerson } from 'react-icons/bs';
import { HiOutlineMenuAlt4 } from 'react-icons/hi';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import RegisterModal from '../register_modal/RegisterModal';
import LoginModal from '../login_modal/LoginModal';

function Navbar() {
    const [nav, setNav] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [modal, setModal] = useState(null);

    const dropdownRef = useRef(null);

    const handleNav = () => setNav(!nav);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);

    const openModal = (modalType) => {
        setModal(modalType);
        setDropdownOpen(false);
    };

    const closeModal = () => setModal(null);

    return (
        <div className={nav ? 'navbar navbar-bg' : 'navbar'}>
            <div className={nav ? 'logo dark' : 'logo'}>
                <h2>AmplifyMyCareer.</h2>
            </div>
            <ul className='nav-menu'>
                <NavLink className="links" to="/"><li>Home</li></NavLink>
                <NavLink className="links" to="https://blog.amplifymycareer.com"><li>Blog</li></NavLink>
                <li>Contact</li>
                <li>Services</li>
                <li>Testimonials</li>
                <li>Pricing</li>
            </ul>
            <div className='nav-icons'>
                <div className='icon-container'>
                    <BiSearch className='icon' style={{ marginRight: '1rem' }} />
                    <div ref={dropdownRef} className='custom-dropdown'>
                        <BsPerson className='icon' onClick={toggleDropdown} />
                        {dropdownOpen && (
                            <ul className='dropdown-menu'>
                                <li onClick={() => openModal('register')}>Register</li>
                                <li onClick={() => openModal('login')}>Login</li>
                                <li><NavLink to="profile">Profile</NavLink></li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
            <div className='hamburger' onClick={handleNav}>
                {!nav ? (<HiOutlineMenuAlt4 className='icon' />) : (<AiOutlineClose style={{ color: '#000' }} className='icon' />)}
            </div>
            <div className={nav ? 'mobile-menu active' : 'mobile-menu'}>
                <ul className='mobile-nav'>
                    <NavLink className="mobile-links" to="/"><li>Home</li></NavLink>
                    <NavLink className="mobile-links" to="blog"><li>Blog</li></NavLink>
                    {/* <li>Contact</li> */}
                    {/* <li>Services</li> */}
                    {/* <li>Testimonials</li> */}
                    {/* <li>Pricing</li> */}
                </ul>
                <div className='mobile-menu-bottom'>
                    <div className='menu-icon'>
                        <button>Search</button>
                        <button>Account</button>
                    </div>
                    <div className='social-icons'>
                        <FaFacebook className='icon' />
                        <FaInstagram className='icon' />
                        <FaTwitter className='icon' />
                        <FaLinkedin className='icon' />
                    </div>
                </div>
            </div>
            {modal === 'register' && <RegisterModal onClose={closeModal} />}
            {modal === 'login' && <LoginModal onClose={closeModal} />}
        </div>
    );
}

export default Navbar;
