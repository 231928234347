import React, { useState } from 'react';
import './WaitlistsignupStyles.css';

const Waitlistsignup = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Send form data to server here
        console.log('Form submitted:', { name, email });
        setSubmitted(true);
    };

    return (
        <div className="waitlist-signup-container">
            {submitted ? (
                <div>
                    <h2>Thank you for signing up!</h2>
                    <p>We will notify you once we are ready.</p>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <h2>Waitlist Sign-Up</h2>
                    <div className='name-container'>
                        <input
                            type="text"
                            id="name"
                            placeholder='Name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className='email-container'>
                        <input
                            type="email"
                            id="email"
                            placeholder='Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <button className= 'submit-container' type="submit">Submit</button>
                </form>
            )}
        </div>
    );
};

export default Waitlistsignup;
