import React from 'react'
import './CalltoactionStyles.css'

import Image from '../../assets/calltoaction.jpg'
import Waitlistsignup from '../waitlistsignup/Waitlistsignup'
// import ResumeUploader from '../resumeuploader/Resumeuploader'

function Calltoaction() {
  return (
    <div className='calltoaction'>
        <img src={Image} alt='' id='image'></img>
        <div className='overlay'></div>
        <div className='content'>
            <h1> Fast Track Your Career with AmplifyMyCareer </h1>
            {/* <h2> Drop Your Resume below to get started </h2> */}
            <h2> Sign up for our waitlist </h2>
            {/* <ResumeUploader /> */}
            <Waitlistsignup />
        </div>
    </div>
  )
}

export default Calltoaction
