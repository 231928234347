import React from 'react';
import './FooterStyles.css';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="links">
                <ul>
                    <li><NavLink className="click-links" to="/">Home</NavLink></li>
                    <li><NavLink className="click-links" to="blog">Blog</NavLink></li>
                    <li><NavLink className="click-links" to="contact">Contact</NavLink></li>
                    <li><NavLink className="click-links" to="services">Services</NavLink></li>
                    <li><NavLink className="click-links" to="testimonials">Testimonials</NavLink></li>
                    <li><NavLink className="click-links" to="pricing">Pricing</NavLink></li>
                </ul>
            </div>
            <div className='social-icons'>
                <FaFacebook className='icon' />
                <FaInstagram className='icon' />
                <FaTwitter className='icon' />
                <FaLinkedin className='icon' />
            </div>
            <div className='copyright'>
                Copyright © 2024 AmplifyMyCareer USA, inc. All rights reserved.
            </div>
            <div className='privacy-policy'>
                Terms of Use | Privacy & Policy.
            </div>
        </footer>
    );
};

export default Footer;
